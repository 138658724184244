import React from 'react';
import { Routes, Route, Link, NavLink } from 'react-router-dom';

const Nav = ({ links, components }) => (  
    <div>
        <nav className="navbar navbar-inverse navbar-static-top">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" href="#">Everything Sharks</a>
                </div>
                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">

                    <ul className="nav navbar-nav mr-auto">
                        {links.map(({ key, href, label }) => {
                            return (
                                <li key={key} >
                                    <NavLink to={href} >{label}</NavLink>
                                </li>
                            );
                        })}
                    </ul>


                </div>
            </div>


        
        </nav>

        <Routes>
        {links.map(({ key, href, label }) => {
            const Component = components[label];
            return <Route key={key} path={href} element={Component ? <Component /> : null} />
        })}
        </Routes>
    </div>
    
);

export default Nav;