import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import './style.css';
import Nav from './components/nav';
import Shark from './components/Shark';
import Home from './components/Home';


const links = [
  { href: '/', label: 'Home' },
  { href: '/shark', label: 'Shark' },
].map((link) => {
  link.key = `nav-link-${link.href}-${link.label}`;
  return link;
});

const components = {
  Home,
  Shark,
  // Add other components here
};


export default function App() {
  return (
    <div>
      <Nav links={links} components={components} />
    </div>
  );
}
